<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      lazy
      id="sidebar-questionnaire"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-questionnaire-to-vendor-form
        :vendorId="this.$route.params.id"
        :reloadParent="load"
        :closeSidebar="closeQuestionnaireSidebar"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-question-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="620px"
    >
      <sidebar-template title="Question Details" :closeAction="closeDetailsSidebar">
        <template #content>
          <response-question-details-view
            :response="selectedResponse"
            :reloadParent="load"
            :assessmentStatus="1"
          />
        </template>
      </sidebar-template>
    </b-sidebar>

    <div class="card" v-if="assessment">
      <div
        class="
          card-header
          d-flex
          flex-row
          align-items-start
          justify-content-between
        "
      >
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          style="width: 65%"
        >
          <h2 class="mb-1 text-primary">{{ assessment.title }}</h2>
          <p>{{ assessment.description }}</p>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-between"
          style="width: 30%"
        >
          <h4>Progress</h4>
          <div class="d-flex justify-content-center align-items-center">
            <donut-bar
              :percentage="Number(assessment.completion.toFixed(2))"
              :displayValue="assessment.completion + '%'"
              size="sm"
              :uniqueId="assessment._id + 2"
              :colorsReversed="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="assessment !== null">
      <div class="card-header">
        <div class="d-flex flex-column align-items-start justify-content-start">
          <h2 class="mb-50 font-weight-bolder">Responses</h2>
          <p>All responses of this assessment are listed below.</p>
        </div>

        <div class="d-flex flex-column align-items-end justify-content-between">
        </div>
      </div>
      <div class="card-body" v-if="questionnaires !== null">
        <div>
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Question</th>
                <th role="columnheader" scope="col" >
                 Response
                </th>
                <th role="columnheader" scope="col" >Complaince</th>
                <!-- <th role="columnheader" scope="col" class="text-center">
                  Progress
                </th> -->
              </tr>
            </thead>
            <tbody role="rowgroup">
              <!-- <tr
                v-for="questionnaire in questionnaires"
                :key="questionnaire._id"
                role="row"
                class="cursor-pointer"
                @click="handleQuestionnaireClick(questionnaire._id)"
              >
                <td role="cell">
                  <div class="d-flex justify-content-start">
                    <p
                      class="
                        text-left text-truncate text-primary
                        font-weight-bolder
                        mb-0
                      "
                      v-b-tooltip.hover.top.v-secondary
                      :title="questionnaire.title"
                    >
                      {{ questionnaire.title }}
                    </p>
                  </div>
                </td>

                <td role="cell">
                  <div class="d-flex justify-content-start">
                    <p
                      class="
                        text-left
                        font-italic
                        text-truncate
                        font-weight-bold
                        mb-0
                      "
                      v-b-tooltip.hover.top.v-secondary
                      :title="questionnaire.description"
                    >
                      {{ questionnaire.description }}
                    </p>
                  </div>
                </td>

                <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-avatar
                      :text="questionnaire.total_questions.toString()"
                      variant="light-primary"
                    />
                  </div>
                </td>

                <td role="cell">
                  <div
                    class="d-flex justify-content-center"
                    style="height:73px: overflow:hidden;"
                  >
                    <donut-bar
                      :percentage="Number(questionnaire.gap.toFixed(2))"
                      :displayValue="questionnaire.gap + '%'"
                      size="xsm"
                      :uniqueId="questionnaire._id + 1"
                    />
                  </div>
                </td>
                <td role="cell">
                  <div
                    class="d-flex justify-content-center"
                    style="height:73px: overflow:hidden;"
                  >
                    <donut-bar
                      :percentage="Number(questionnaire.completion.toFixed(2))"
                      :displayValue="questionnaire.completion + '%'"
                      size="xsm"
                      :uniqueId="questionnaire._id + 2"
                      :colorsReversed="true"
                    />
                  </div>
                </td>
              </tr> -->
              <tr @click="handleDetailsViewClick(resp)" v-for="(resp,index) in responses" :key="resp._id">
                <td>{{ index + 1 }}</td>
                <td>{{ resp.title }}</td>
                <td><b-badge variant="danger">{{ resp.response == 1 ? 'yes':resp.response == 2?'no':resp.response == 3?'not applicable':'' }}</b-badge></td>
                <td><b-badge variant="danger">{{ resp.status == 1 ? 'yes':resp.status == 2?'no':resp.status == 3?'not applicable':'' }}</b-badge></td>
              </tr>
            </tbody>
          </table>
          <div class="m-2">
            <div class="row">
              <div
                class="d-flex align-items-center justify-content-center col-12"
              >
                <b-pagination
                  v-model="filters.page"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.total"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BPagination,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import AddUserToVendorForm from "./components/AddUserToVendorForm.vue";
import AddQuestionnaireToVendorForm from "./components/AddQuestionnaireToVendorForm.vue";
import DonutBar from "@/components/DonutBar.vue";
import TaskMixins from "../../mixins/TaskMixins";
import AddAssessmentToVendorForm from "./components/AddAssessmentToVendorForm.vue";
import VendorMixins from "../../mixins/VendorMixins";
import ResponseQuestionDetailsView from "./ThirdPartyRisksRA/components/ResponseQuestionDetailsView.vue";
import SidebarTemplate from "../../components/SidebarTemplate.vue";
export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    AddUserToVendorForm,
    AddQuestionnaireToVendorForm,
    AddAssessmentToVendorForm,
    DonutBar,
    BPagination,
    BBadge,
    ResponseQuestionDetailsView,
    SidebarTemplate
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      assessment: null,
      questionnaires: null,
      filters: {
        page: 1,
      },
      pagination: {
        perPage: 10,
        total: 1,
      },
      isUserVendor: false,
      responses:[],
      selectedResponse:[]
    };
  },

  mixins: [
    ThirdPartyRisksMixins,
    ResponseMixins,
    UtilsMixins,
    TaskMixins,
    VendorMixins,
  ],

  watch: {
    filters: {
      handler: function (newFilters) {
        this.getAssessmentQuestionnairesOfVendorAndSetData(
          newFilters,
          this.$route.params.id,
          this.$route.params.assessmentId
        );
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
    this.getVendorResponses();
  },
  methods: {
    handleDetailsViewClick(response) {
      this.selectedResponse = response;
      this.openDetailsSidebar();
    },

    closeDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
    },
    openDetailsSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
      });
    },

    getVendorResponses(){
      this.getandsetVendorResponses(this.$route.params.id,this.$route.params.assessmentId).then((res)=>{
        this.responses = res.data.data.data
      })
      .catch((err)=>{
        console.log(err)
      })
    },
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getAssessmentDetailsAndSetData(
        this.$route.params.id,
        this.$route.params.assessmentId
      );
      this.getAssessmentQuestionnairesOfVendorAndSetData(
        this.filters,
        this.$route.params.id,
        this.$route.params.assessmentId
      );
      // this.getAssessmentsOfVendorAndSetData({}, this.$route.params.id);
    },

    handleQuestionnaireClick(questionnaireId) {
      this.$router.push(
        `/thirdpartyrisks/${this.$route.params.id}/details/assessment/${this.$route.params.assessmentId}/questionnaire/${questionnaireId}`
      );
    },

    getAssessmentDetailsAndSetData(vendorId, assessmentId) {
      this.showOverlay = true;
      this.getAssessmentDetailsOfVendor(vendorId, assessmentId)
        .then((res) => {
          this.assessment = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    

    getAssessmentQuestionnairesOfVendorAndSetData(
      params,
      vendorId,
      assessmentId
    ) {
      this.showOverlay = true;
      this.getAssessmentQuestionnairesOfVendor(params, vendorId, assessmentId)
        .then((res) => {
          this.questionnaires = res.data.data.data;
          this.filters.page = res.data.data.current_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.total = res.data.data.total;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewUserClick() {
      this.openSidebar();
    },

    // getAssessmentsOfVendorAndSetData(filters, vendorId) {
    //   this.getAssessmentsOfVendor(filters, vendorId).then((res) => {
    //     this.assessments = res.data.data.data;
    //   });
    // },

    handleAddQuestionnaireClick() {
      this.openQuestionnaireSidebar();
    },

    getVendorDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getVendorDetails(id)
        .then((res) => {
          this.vendor = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({}, vendorId)
        .then((res) => {
          console.log("Questions", res.data.data.data);
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
    },
    openAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
      });
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    closeQuestionnaireSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-questionnaire");
    },
    openQuestionnaireSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-questionnaire");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>